<template>
  <div class="homePage" v-loading="isLoading">
    <div class="banner row" style="-webkit-app-region: drag;">
      <div class="title col">{{ shopName }}</div>
      <!-- <div class="opt active">历史订单</div> -->
      <div class="tab" style="-webkit-app-region: no-drag;">
        <div class="row row-center">
          <el-tooltip effect="dark" content="呼叫网管" placement="left" v-if="shop && shop.shopId < 9000">
            <img class="phone-icon" src="~@/assets/images/phone.png" @click.stop="callNetwork"/>
          </el-tooltip>

          <div class="item" :class="{'active': tabkey==item.key}" v-for="(item, index) of tabList" :key="index"
               @click.stop="changeTab(item.key)">
            {{ item.title }}
          </div>
          <!-- <div class="item" :class="{'active': tab==1}"><router-link :to="{name: 'Shop'}">超市</router-link></div>
          <div class="item" :class="{'active': tab==2}"><router-link :to="{name: 'Wallet'}">钱包</router-link></div> -->
        </div>
      </div>
    </div>
    <div class="close" @click="closeWin" style="-webkit-app-region: no-drag;"></div>
    <!--签到-->
    <div class="register-tip" v-show="registerShow">
      <div class="register-bg">
        <div class="register-all">
          <Register ref="registerChild"></Register>
        </div>
        <div class="register-close" style="-webkit-app-region: no-drag;" @click="handleRegisterClose">
          <img src="~@/assets/images/balance/user/close.png"/>
        </div>
      </div>
    </div>
    <shop v-if="tabkey==1"></shop>
    <guessing v-if="tabkey==2"></guessing>
    <wallet v-if="tabkey==3"></wallet>
  </div>
</template>

<script>
import { getMemberInfo } from '@/api/member'
import { decryptByDESModeCBC, encryptByDESModeCBCUtf8to64 } from '@/common/utils/des'
import { callNetwork } from '@/api/wallet'
import Shop from '@/views/shop'
import Wallet from '@/views/balance/wallet'
import Guessing from '@/views/balance/guessing'
import Register from '@/components/SignIn/register'
import {mapState} from 'vuex'
export default {
  name: 'HomePage',
  components: {
    Shop,
    Wallet,
    Guessing,
    Register
  },
  data () {
    return {
      ipc: null,
      isLoading: false,
      tabList: [{
        title: '超市',
        key: 1
      }, {
        title: '娱乐竞猜',
        key: 2
      }, {
        title: '钱包',
        key: 3
      }, {
        title: '签到',
        key: 4
      }],
      tabkey: 0,
      info: {},
      registerShow: false,
    }
  },
  computed: {
    ...mapState(['shop', 'shopName'])
  },
  methods: {
    changeTab (key) {
      if (key == 4) {
        this.handleRegisterOpen()
        return
      }
      this.tabkey = key
    },
    initData () {
      var query = this.$route.query
      this.tabkey = query.tab || this.tabList[0].key
      if (!query.secret) {
        this.$message.error('非法登录')
        return
      }
      this.$store.commit('setShop', JSON.parse(decryptByDESModeCBC(query.secret)))
      this.$store.commit('setSecret', decodeURIComponent(query.secret))
      this.getInfo(query.secret)
    },
    getInfo (data) {
      getMemberInfo({
        data: data
      }).then(res => {
        if (res.code == 200) {
          this.info = JSON.parse(decryptByDESModeCBC(res.msg))
          this.$store.commit('login', this.info)
          return false
        }
        this.$message.error('会员已下机')
      })
    },
    closeWin () {
      if (this.ipc) {
        this.ipc.send('window-shop-min')
      } else {
        console.log('窗体JS还未加载完毕')
      }
    },
    clearInter () {
      if (this.inter) {
        clearInterval(this.inter)
      }
    },
    callNetwork () {
      var timestamp = new Date().getTime()
      this.oldTime = sessionStorage.oldTime
      if (this.oldTime) {
        /**
         * 判断是否有30s超过了可以继续呼叫
         */
        let times = Math.abs(timestamp - this.oldTime) >= (0.5 * 60 * 1000)
        if (!times) {
          this.$message.error('呼叫太频繁了~')
          return
        }
      }
      callNetwork({
        type: 1,
        message: ''
      }).then(res => {
        if (res.code == 200) {
          sessionStorage.oldTime = res.data.time
          this.$message({
            message: '已呼叫网管,请稍等~',
            type: 'success',
          })
        }
      })
    },
    // 签到-打开
    handleRegisterOpen () {
      this.registerShow = true
      this.$refs.registerChild.handleListData(this.info)
    },
    // 签到-关闭
    handleRegisterClose () {
      this.registerShow = false
      sessionStorage.registerShowHide = true
    },
  },
  created () {
    this.initData()
  },
  mounted () {
    this.isLoading = false
    if (!this.inter) {
      this.inter = setInterval(() => {
        if (!window.Qbian) return
        this.clearInter()
        this.ipc = window.Qbian.ipc

        this.ipc.send('changePage')
        this.ipc.on('changePage', (event, data) => {
          console.log('-------- changePage', data)
          this.tabkey = data
        })
        this.ipc.on('quit', (event, data) => {
           this.ipc.send('quit')
        })
        this.ipc.on('callNetwork', (event, data) => {
          this.callNetwork()
        })
      }, 1000)
    }

  },
  destroyed () {
    this.clearInter()
  }
}
</script>
<style lang="less">
@import "./index.less";
</style>
