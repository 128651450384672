<template>
  <div class="home">
    <!-- <div class="header row">
        <div class="title col">
            <img src="@/assets/images/home/title.png" alt="">
        </div>
    </div> -->

    <div class="info">
      <div class="row">
        <div class="home-item home-item-1" @click.stop="openChildWin(1)"></div>
        <div class="home-item home-item-2" @click.stop="openChildWin(2)"></div>
        <div class="home-item home-item-3" @click.stop="openChildWin(3)"></div>
        <div class="home-item home-item-5" @click.stop="openChildWin(4)" v-if="parseInt(this.shop.shopId) == 9102"></div>
        <div class="home-item home-item-4" @click.stop="openChildWin(4)" v-else></div>
      </div>
    </div>
  </div>
</template>
<script>
import { memberDeplane, getMemberInfo } from '@/api/member'

// import { encryptDes, decryptDes,generatekey }  from '@/common/utils/des'
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC } from '@/common/utils/des'
import {callNetwork} from "@/api/wallet";
// import { TIM } from "../../tim";
// import TIMUploadPlugin from 'tim-upload-plugin'

export default {
  name: 'Home',
  data () {
    return {
      oldTime: '',
      shop: ''
    }
  },
  methods: {
    openChildWin (type) {
      var info = this.info
      switch (type) {
        case 1:
          if (window.Qbian) {
            var ipc = window.Qbian.ipc
            ipc.send('window-shop-open')
          }
          break
        case 2:
          if (window.Qbian) {
            var ipc = window.Qbian.ipc
            ipc.send('window-balance-open')
          }
          break
        case 3:
          console.log('结账')
          var ipc = window.Qbian.ipc
          ipc.send('window-check-open')
          // this.checkDeplane();
          break
        case 4:
          console.log('评论')
          if (window.Qbian) {
            var ipc = window.Qbian.ipc
            if (parseInt(this.shop.shopId) == 9102) {
              ipc.send('callNetwork')
              return
            }
            ipc.send('window-comment', window.location.protocol + '//' + window.location.host + '/#/comment')
          }
          break
        default:
          break
      }
    },

    checkDeplane () {
      var info = this.info, _this = this
      this.$confirm('确定要结账下机?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
        customClass: 'home-modal'
      }).then(() => {
        if (window.Qbian) {
          var ipc = window.Qbian.ipc
          ipc.send('member-deplane')
          ipc.once('member-deplane', (event, secret) => {
            memberDeplane({
              secret: secret
            }).then(res => {
              if (res.code == 200) {
                _this.$message({
                  message: '结账成功~',
                  type: 'success',
                  customClass: 'home-modal'
                })
                ipc.send('window-close')
              } else {
                _this.$message({
                  message: res.msg,
                  type: 'error',
                  customClass: 'home-modal'
                })
              }
            })
          })
        }

      }).catch(() => {

      })
    },

    getMemberInfo (secret) {
      getMemberInfo({
        data: secret
      }).then(res => {
        if (res.code == 200) {
          let info = JSON.parse(decryptByDESModeCBC(res.msg))
          this.$store.commit('login', info)
        }
      })
    }
  },
  created () {

  },
  mounted () {
      var query = this.$route.query
      if (query.secret) {
        query.secret = query.secret.replace(/\s/g, '+')
        this.$store.commit('setSecret', decodeURIComponent(query.secret))
        let shop = JSON.parse(decryptByDESModeCBC(query.secret))
        this.shop = shop
        this.$store.commit('setShop', shop)
        this.getMemberInfo(query.secret)
      }
  },
  destroyed () {
  }
}
</script>
<style lang="less">
@import "./index.less";
</style>
