<style lang="less">
@import "./index.less";
</style>
<template>
  <div>
    <comment-header/>
    <div class="comment">
      <div class="warp">
        <div class="comment-ipt">
          <div class="ipt-top row">
            <div class="title col">评价留言板</div>
            <div>
              <div class="row">
                <div class="check" :class="params.type==key?'active':''" v-for="(val,key) of commentTypeList" :key="key" @click.stop="params.type=key">
                  <i></i>{{val}}
                </div>
              </div>
            </div>
          </div>
          <div class="ipt-tips">请勾选下列评价类型后，再进行评价</div>
          <div class="ipt-type row">
            <div class="check" :class="params.contentType==key?'active':''" v-for="(val,key) of typeList" :key="key" @click.stop="params.contentType=key">
            {{val}}<i></i>
            </div>
          </div>
          <div class="ipt-textarea">
            <el-input type="textarea" v-model="params.content" placeholder="请输入您的宝贵评价" resize="none"></el-input>
          </div>
          <div class="confirm" :class="params.content&&params.content.length>0?'active':''" @click="confirm">发送</div>
        </div>
        <div class="comment-list">
          <div class="comment-item" v-for="(item,index) of commentList" :key="index" >
            <div class="comment-info row">
              <img class="head" src="@/assets/images/comment/head.png" alt="">
              <div class="name">{{item.name}}</div>
              <div class="date" v-if="item.createTime">发表于{{ $utils.dateFormat(new Date(item.createTime.replace(/-/g, "/")).getTime(),'yyyy-MM-dd hh:mm:ss')}}</div>
            </div>
            <div class="comment-content">
              <div class="comment-cnt" :class="`open-`+item.isOpen">
                <div class="line-clamp" :class="`open-`+item.isOpen" :ref="'cnt-' + index">
                  <span v-if="item.content.length>=213 && item.isOpen == 1">
                    {{item.content.substring(0,213)}}...
                    <label @click.stop="open(index,2)" class="cnt-more">展开</label>
                  </span>
                  <span v-else>{{item.content}}</span>
                </div>
              </div>
              <div class="comment-reply" v-if="item.replyContent">
                <i></i>
                <label class="comment-lb">门店回复：</label><label>{{item.replyContent}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="more" ref="more" v-if="commentList && commentList.length >0" >
            <div v-if="!isMore">没有更多~</div>
            <el-button v-else :loading="moreload" class="more-btn">加载更多...</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commentHeader from '@/components/comment/header'
import { getCommentList, addComment, getShopInfo } from "@/api/comment";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
  name: 'comment',
  components: {
    commentHeader
  },
  data() {
    return {

      commentTypeList: {
        1: '好评评价',
        2: '建议反馈',
        3: '投诉差评'
      },
      typeList: {
        1: '服务类：',
        2: '硬件类：',
        3: '商品类：',
        4: '环境类：',
        5: '软件类：'
      },
      moreload: false,
      isMore: false,
      commentList: null,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        secret: ''
      },
      params: {
        type: 1,
        contentType: 1,
        content: null
      }
    }
  },

  methods: {
    open(index,isOpen){
      this.commentList[index].isOpen = isOpen
    },
    getList(callback){
      var queryParams = this.queryParams;
      if(queryParams.secret){
        getCommentList(queryParams).then(res=>{
          if(res.code == 200){
            var list = this.commentList || [];
            if(queryParams.pageNum == 1){
              list = res.rows
            }else{
              list = list.concat(res.rows)
            }
            
            if(list.length>0){
              list = list.map((item,index)=>{
                if(item.isOpen!=2){
                  item.isOpen = 0
                }
                return item;
              })
            }
            this.commentList = list;
            var _this = this;
            this.$nextTick(() => {
              if(list.length>0){
                list.map((item,index)=>{
                  if(item.isOpen!=2){
                    _this.commentList[index].isOpen = _this.$refs['cnt-'+index][0].offsetHeight>=60?1:0
                  }
                })
              }
            });

            this.isMore = queryParams.pageNum * queryParams.pageSize< res.total;
            this.total = res.total;
            if(callback){
              callback();
            }
          }
        })
      }else{
        this.$message.error("非法登录");
      }
    },
    confirm(){
      if(!this.queryParams.secret){
        this.$message.error("非法登录");
        return false;
      }
      if(!this.params.content){
        // this.$message.error("请输入您的宝贵评价");
        return false;
      }
      addComment(this.params).then(res=>{
        if(res.code == 200){
          this.$message.success(res.msg);
          this.getList();
        }
        Object.assign(this.$data.params, this.$options.data().params);
      })
    },
    handleScroll(){
      if(this.$refs.more.getBoundingClientRect().top - document.body.clientHeight<60){
        if(this.isMore && !this.moreload){
          this.moreload = true;
          this.queryParams.pageNum += 1;
          this.getList(()=>{
            setTimeout(()=>{
              this.moreload = false;
            },500)
          })
        }
      }
    }
  },
  created(){

  },
  mounted() {
    var query = this.$route.query;
    // query.secret = encryptByDESModeCBCUtf8to64(JSON.stringify({
    //   shopId: 7468,
    //   deviceNumber: 'A001',
    //   d: new Date().getTime()
    // }))
    this.queryParams.secret = query.secret?decodeURIComponent(query.secret):'';
    sessionStorage.setItem("secret",this.queryParams.secret)
    this.getList();
    window.addEventListener('scroll', this.handleScroll,true);
  },
  destroyed(){

  }
}
</script>
